import React from 'react'
import Typography from '@material-ui/core/Typography'

import Header from '../../../components/sections/Header'
import Text from '../../../components/sections/Text'
import Film from '../../../components/sections/Film'
import Essay from '../../../components/sections/Essay'
import Soundtrack from '../../../components/sections/Soundtrack'
import Gallery from '../../../components/sections/Gallery'
import Footer from '../../../components/sections/Footer'
import { LinkTab } from '../../../components/Link'

import background from '../../../assets/images/nelson-makengo/background.png' 

import img1 from '../../../assets/images/nelson-makengo/1.jpg' 
import img2 from '../../../assets/images/nelson-makengo/2.jpg' 
import img3 from '../../../assets/images/nelson-makengo/3.jpg' 
import img4 from '../../../assets/images/nelson-makengo/4.jpg' 
import img5 from '../../../assets/images/nelson-makengo/5.jpg' 
import img6 from '../../../assets/images/nelson-makengo/6.jpg' 
import img7 from '../../../assets/images/nelson-makengo/7.jpg' 

const slides = [
  {
    label: 'Nelson Makengo',
    image: img1
  },
  {
    label: 'Nelson Makengo',
    image: img2
  },
  {
    label: 'Nelson Makengo',
    image: img3
  },
  {
    label: 'Nelson Makengo',
    image: img4
  },
  {
    label: 'Nelson Makengo',
    image: img5
  },
  {
    label: 'Nelson Makengo',
    image: img6
  },
  {
    label: 'Nelson Makengo',
    image: img7
  },

];

export default function Album() {

  return (
    <>

      <Header
        name='Nelson Makengo'
        title='Up at Night'
        date='14 May - 10 June 2021'
      />
      
      <Text>
        <Typography variant='subtitle1'>
          As dusk fades and another night without electricity falls, Kinshasa's neighborhoods reveal an environment of violence, political conflict, and uncertainty over the building of the Grand Inga 3 hydroelectric dam, which promises a permanent source of energy to the Congo.
        </Typography>
      </Text>

      <Film 
        url="567000993?h=5bfb37ad5b"
        title="Nelson Makengo, Up at Night, 2018, 20 min. 10”"
        titleShort="Nelson Makengo - Up at Night"
      />
     
      <Essay 
        title="Filip De Boeck"
        subtitle="Kinshasa and its (Im)material Infrastructure"
        url='/articles/filip-de-boeck-kinshasa-and-its-immaterial-infrastructure'
      >
      <Typography
        paragraph variant='subtitle1'
      >
        In ongoing discussions concerning the nature of the African city architects, urban planners, sociologists, anthropologists, demographers and others devote a lot of attention to the built form, and more generally to the city’s material infrastructure. Architecture has become a central issue in western discourses and reﬂections on how to plan, engineer, sanitize and transform the urban site and its public spaces. Mirror-ing that discourse, architecture has also started to occupy an increasingly important place in attempts to come to terms with the speciﬁcities of the African urbanscape and to imagine new urban paradigms for the African city of the future. Indeed, one can hardly underestimate the importance of the built form and of the material, physical infrastructure if one wants to understand the ways the urban space unfolds and designs itself. For example, studying the process of the “bunkerization” of the city, as it is called by its inhabitants, that is the fact that one of Kinshasa’s crucial spaces, the compound, has evolved from an open space lined by ﬂowers and shrubs in the 1940s and 50s to today’s closed parcelles, surrounded by high walls that make the inside invisible to the street, would certainly contribute to a better understanding of the city’s his-tory of unraveling social relationships, its altered sense of security and its changing attitude towards the qualities of public and private.
      </Typography>
      </Essay>
      
      <Soundtrack 
        title="Nelson Makengo"
        subtitle="Saint Mary Church field recording"
        caption="2018, 25 min. 55”"
        image={background}
        color='#fff'
        url="https://soundcloud.com/felice-moramarco-726293220/nelson-makengo-saint-mary-church/s-m1XOP36jcw4"
      />

      <Gallery 
        slides={slides}
        title='Nelson Makengo, Untitled, 2017'
      />
      
      <Footer />


    </>
  );
}