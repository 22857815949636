import React from 'react'

import Layout from '../../layouts/Artist'
import Main from '../../views/artists/nelson-makengo'

import background from '../../assets/images/nelson-makengo/background.png'


const Default = () => {

  const mainView = (
    <Layout background={background}>
      <Main />
    </Layout>
  )

  return mainView
}

export default Default
